// Not in use! Use NewChat.js



import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAccount } from 'wagmi';
import EmojiPicker from 'emoji-picker-react';
import { MessageCircle, MessagesSquare } from 'lucide-react';

// Import Degen images
import Degen1 from '../images/Degen1.png';
import Degen2 from '../images/Degen2.png';
import Degen3 from '../images/Degen3.png';
import Degen4 from '../images/Degen4.png';
import Degen5 from '../images/Degen5.png';
import Degen6 from '../images/Degen6.png';
import Degen7 from '../images/Degen7.png';
import Degen8 from '../images/Degen8.png';
import Degen9 from '../images/Degen9.png';
import Degen10 from '../images/Degen10.png';
import Degen11 from '../images/Degen11.png';
import Degen12 from '../images/Degen12.png';
import Degen13 from '../images/Degen13.png';
import Degen14 from '../images/Degen14.png';
import Degen15 from '../images/Degen15.png';
import Degen16 from '../images/Degen16.png';
import Degen17 from '../images/Degen17.png';
import Degen18 from '../images/Degen18.png';
import Degen19 from '../images/Degen19.png';
import Degen20 from '../images/Degen20.png';

const degenImages = [
  Degen1, Degen2, Degen3, Degen4, Degen5,
  Degen6, Degen7, Degen8, Degen9, Degen10,
  Degen11, Degen12, Degen13, Degen14, Degen15,
  Degen16, Degen17, Degen18, Degen19, Degen20
];

const adminAddresses = [
  '0x7308BD2ea2bc85B44e44c64B30c14C189F75531d'.toLowerCase(),
  '0x7384e9D49283D1195D1e8FD6515D72d622e65a70'.toLowerCase()
];

const trimAddress = (address) => `${address.slice(0, 6)}...${address.slice(-4)}`;

const hashCode = (str = '') => {
  return str.split('').reduce((prevHash, currVal) =>
    ((prevHash << 5) - prevHash) + currVal.charCodeAt(0), 0);
};

const getAvatarForAddress = (address = '') => {
  const hash = Math.abs(hashCode(address));
  const imageIndex = hash % degenImages.length;
  const dgnNumber = `DGN${(hash % 1000).toString().padStart(3, '0')}`;
  return { avatar: degenImages[imageIndex], dgnNumber };
};

const Chat = () => {
  const { address, isConnected } = useAccount();
  const isAdmin = adminAddresses.includes(address?.toLowerCase());
  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    const loadedMessages = savedMessages ? JSON.parse(savedMessages) : [];

    return loadedMessages.map(msg => ({
      ...msg,
      ...getAvatarForAddress(msg.address || ''),
    }));
  });
  
  const [newMessage, setNewMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [systemMessage, setSystemMessage] = useState(null); 
  const chatMessagesRef = useRef(null);

  const { avatar, dgnNumber } = useMemo(() => getAvatarForAddress(address || ''), [address]);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    chatMessagesRef.current?.scrollTo(0, chatMessagesRef.current.scrollHeight);
  }, [messages, systemMessage]);

  const blockedWords = ['badword1', 'badword2', 'badword3']; 
  const containsBlockedWords = (message) => {
    return blockedWords.some(word => message.toLowerCase().includes(word));
  };

  const [lastMessageTime, setLastMessageTime] = useState(null);

  const handleSendMessage = (e) => {
    e.preventDefault();
    const now = Date.now();

    if (lastMessageTime && now - lastMessageTime < 5000) { 
      setSystemMessage("You're sending messages too fast!");
      return;
    }

    if (containsBlockedWords(newMessage)) {
      setSystemMessage('Your message contains inappropriate content.');
      setNewMessage(''); 
      return;
    }

    if (newMessage.trim()) {
      setMessages([...messages, { address, message: newMessage, avatar, dgnNumber }]);
      setNewMessage('');
      setShowEmojiPicker(false);
      setLastMessageTime(now);
      setSystemMessage(null); 
    }
  };

  const handleEmojiSelect = (emojiObject) => {
    setNewMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const handleDeleteMessage = (index) => {
    setMessages(messages.filter((_, i) => i !== index));
  };

  return (
    <div className="degenchat-container">
      <div className="degenchat-header">
        <a 
          href="https://t.me/yourtelegramlink" 
          target="_blank" 
          rel="noopener noreferrer"
          className="degenchat-social-link"
        >
          <MessageCircle size={24} />
        </a>
        <span className="degenchat-header-text">The Trenches</span>
        <a 
          href="https://discord.gg/yourdiscordlink" 
          target="_blank" 
          rel="noopener noreferrer"
          className="degenchat-social-link"
        >
          <MessagesSquare size={24} />
        </a>
      </div>
      <div className="degenchat-wrapper">
        <div className="degenchat-messages" ref={chatMessagesRef}>
          {messages.map((msg, idx) => (
            <div key={idx} className="degenchat-message">
              <div className="degenchat-avatar-container">
                <img src={msg.avatar} alt="avatar" className="degenchat-avatar" />
                <div className="degenchat-dgn-number">{msg.dgnNumber}</div>
              </div>
              <div className="degenchat-message-content">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="wallet-address">
                    {adminAddresses.includes(msg.address.toLowerCase()) ? "Mod" : trimAddress(msg.address)}
                  </span>
                </div>
                <div className="degenchat-message-bubble">
                  {msg.message}
                  {isAdmin && (
                    <button
                      className="degenchat-delete-button"
                      style={{ display: isAdmin ? 'block' : 'none' }}
                      onClick={() => handleDeleteMessage(idx)}
                    >
                      X
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
          {systemMessage && <div className="degenchat-system-message">{systemMessage}</div>}
        </div>
        {isConnected && (
          <div className="degenchat-input-container">
            <form onSubmit={handleSendMessage} style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                value={newMessage}
                maxLength={200}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Your Message"
                className="degenchat-input"
              />
              <button 
                type="button" 
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="degenchat-emoji-button"
              >
                😊
              </button>
              <button type="submit" className="degenchat-send-button">
                Send
              </button>
            </form>
            {showEmojiPicker && (
              <div className="degenchat-emoji-picker-wrapper">
                <EmojiPicker
                  theme="dark"
                  onEmojiClick={handleEmojiSelect}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;